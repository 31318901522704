import React, { useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import Button from "../common/Button";

import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { addSignatures, reset } from "../../features/auth/authSlice";

const COPPAComplianceModal = ({ onContinueClick }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );

  const [agreeTerms, setAgreeTerms] = useState(false);
  const [sigPad, setSigPad] = useState(null);

  const handleTermsChange = () => {
    setAgreeTerms(!agreeTerms);
  };

  const clearSignature = () => {
    sigPad.clear();
  };

  const saveSignature = () => {
    if (!agreeTerms) {
      toast.error("You must agree to the terms and conditions.");
      return;
    }

    if (sigPad.isEmpty()) {
      toast.error("Please provide a signature.");
    } else {
      const signatureData = sigPad.toDataURL();
      dispatch(addSignatures({ digitalSign: signatureData }));
    }
  };

  useEffect(() => {
    if (isError) {
      toast.error("Failed to save the signatures. Please try again!");
      dispatch(reset());
    }

    if (user?.onboarded && isSuccess) {
      toast.success("Signatures saved.");
      onContinueClick();
      dispatch(reset());
      navigate("/");
    }
  }, [isSuccess, isError, message]);

  return (
    <div className="absolute p-5 inset-0 size-full flex items-start justify-center">
      <div className="bg-white rounded-lg p-7 md:p-6 lg:p-8 xl:p-10 max-w-[90%] md:max-w-[85%] lg:max-w-[60%] xl:max-w-[75%] flex flex-col gap-4 md:gap-4 lg:gap-5 xl:gap-8">
        <div className="text-center space-y-1 md:space-y-3">
          <h1 className="font-urbanistBold md:text-[16px] xl:text-xl 2xl:text-2xl">
            COPPA Compliance Policy
          </h1>
          <p className="text-[10px] md:text-[16px] text-left font-urbanistMedium leading-tight">
            Protecting Children's Privacy<br /><br />
            The Reunite Registry complies with the Children's Online Privacy Protection Act (COPPA) and is designed for parents and legal guardians to manage contact details on behalf of children. We do not knowingly collect information directly from children under 13. Parental consent is required before storing any child's information, which is confirmed by signing below.<br /><br />
            <strong>Information Collected</strong><br />
            Guardians may choose to store the following:
            <ul className="list-disc list-inside">
              <li>Child's Initials and Last Name (Optional)</li>
              <li>Guardian & Emergency Contact Phone Numbers (Optional)</li>
              <li>Allergy & Additional Notes (Optional)</li>
              <li>Photo of the Child with Guardian (Optional)</li>
            </ul>
            Providing any information is entirely optional. Guardians decide what to enter and can update or delete details at any time.<br /><br />
            <strong>Data Access & Security</strong>
            <ul className="list-disc list-inside">
              <li>Any information stored on an NFC product is publicly accessible when scanned.</li>
              <li>Guardians are responsible for managing their own stored data.</li>
              <li>We work with trusted third-party providers to maintain the website, ensuring privacy and security.</li>
            </ul>
            This agreement is required for all users of The Reunite Registry. However, COPPA protections apply only to accounts created on behalf of children under 13. If you are registering an NFC product for an adult or pet, this clause does not affect your ability to store and manage information. If you have any questions, please contact us at: support@thereunite.com
          </p>
        </div>

        <div className="flex flex-col gap-5 md:gap-3 lg:gap-4 xl:gap-7">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="agreeTerms"
              checked={agreeTerms}
              onChange={handleTermsChange}
              className="w-[22px] h-[22px] cursor-pointer"
            />
            <label
              htmlFor="agreeTerms"
              className="ml-2 font-urbanistMedium text-[14px] md:text-[12px] xl:text-[16px] 2xl:text-lg"
            >
              I agree to all <Link to="/terms-and-conditions" className="text-primary">Terms & Conditions</Link>
            </label>
          </div>

          <div className="flex flex-col gap-3">
            <h1 className="font-urbanistBold md:text-[16px] xl:text-xl 2xl:text-2xl">
              Digital Signature
            </h1>
            <div
              id="signature"
              className="border border-dashed border-primary rounded-xl bg-[#53C2E30D] px-8 py-6 h-[100px] md:h-[100px] lg:h-[120px] xl:h-[140px] 2xl:h-[150px]"
            >
              <SignatureCanvas
                penColor="black"
                canvasProps={{ className: "signatureCanvas" }}
                ref={(ref) => setSigPad(ref)}
              />
            </div>
            <button onClick={clearSignature} className="mt-2 text-blue-500">
              Clear
            </button>
          </div>

          <div className="w-full flex flex-col gap-3">
            <Button
              variant="filled"
              onClick={saveSignature}
              className="flex justify-center items-center"
            >
              {isLoading ? <div className="loader"></div> : "Continue"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default COPPAComplianceModal;
