import axios from "axios";
import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

export default function EnrolleDetails() {
  const [enrolleData, setEnrolleData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState();
  const { id } = useParams();
  const navigate = useNavigate();

  const fetchEnrolleDetails = async () => {
    try {
      setIsLoading(true)
      const API_URL = `${process.env.REACT_APP_API_URL}/enrollee/${id}`;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.get(`${API_URL}`, config);
      console.log(response.data)
      if (response.status === 200) {
        // console.log(response.data)
        setEnrolleData(response.data)
      } else {
        console.log(response)
      }

    } catch (error) {
      if (error.response.status === 404) {
        setError("No such user found!")
      }
      console.log(error.response.data);
      // navigate("/")
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (id) {
      fetchEnrolleDetails();
    } else {
      navigate("/")
    }
    // console.log(id)d
  }, [])

  return (
    <>
      <div className='max-w-screen-xl mx-auto'>
        <div className='px-5 sm:px-10 md:px-20'>
          <div className='py-4 border-b border-primary/50'>
            <Link to={"/"}>
              <img src='/logo.png' className='h-16' />
            </Link>
          </div>
          <div>
            <div className=" bg-blue-50 w-full py-12">
              {
                error ?
                  <div className="text-center text-xl font-medium space-y-5">
                    <p className="capitalize">{error}</p>
                    <div>
                      <Link to={'/'}>
                        <p className="underline font-semibold text-primary">Go Home</p>
                      </Link>
                    </div>
                  </div>
                  :
                  <div className="max-w-md mx-auto bg-primary/20 rounded-xl overflow-hidden w-full shadow-xl shadow-primary">
                    <div>
                      {isLoading ?
                        <div className="flex items-center justify-center h-[67vh]">
                          <svg aria-hidden="true" className="size-16 animate-spin text-blue-50 " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="#53C2E3"

                            />
                          </svg>
                        </div>
                        :
                        <>
                          <div className="border-b px-4 ">
                            <div className="text-center my-4">
                              <img className="h-32 w-32 rounded-full border-4 border-primary mx-auto my-4"
                                src={enrolleData?.profileImg || "/logo.png"} alt="user" />
                              <div className="py-2">
                                <h3 className="font-bold text-4xl">{enrolleData?.firstName || ""} {enrolleData?.lastName || ""}</h3>
                              </div>
                              <div className="py-2">
                                <h3 className="font-medium text-2xl text-gray-500 mb-4">In case of an emergency, please contact my guardian for assistance.</h3>
                              </div>
                              <div className="pb-4 space-y-1.5">
                                <h2 className="font-semibold text-xl">Guardian Name</h2>
                                <h3 className="font-medium text-lg text-gray-700 mb-1 capitalize">{enrolleData?.addedBy?.fullName}</h3>
                              </div>
                              <div className="pb-4 space-y-1.5">
                                <h2 className="font-semibold text-xl text-red-600">Allergy Name</h2>
                                <h3 className="font-medium text-lg text-gray-700 mb-1 capitalize">{enrolleData?.allergyName}</h3>
                              </div>
                              <div className="pb-4">
                                <h2 className="font-semibold text-xl text-red-600">Description</h2>
                                <h3 className="font-medium text-lg text-gray-700 mb-1 capitalize">{enrolleData?.allergyDescription}</h3>
                              </div>
                            </div>
                          </div>
                          <div className="px-4 py-4">
                            <div className="text-center space-y-3">
                              <h3 className="font-extrabold text-3xl text-red-600">Guardian contact Numbers</h3>
                              <div className="space-y-1.5">
                                {
                                  enrolleData && enrolleData?.contactNumbers.map((number, index) => (
                                    <p key={index} className="text-pretty">{number}</p>
                                  ))
                                }
                              </div>
                            </div>

                          </div>
                        </>
                      }
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
